import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentArrowDownIcon from '@heroicons/react/24/outline/DocumentArrowDownIcon';
import BookingSheet from '@/components/Bookings/BookingSheet';
import { DocumentsList } from '@/components/DocsUtils';
import { Button } from '@/components/Layout';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { PageContainer, PageHeader } from '@/components/PageContainer';
import QueryWrapper from '@/components/QueryWrapper';
import ResponsiveContainer from '@/components/ResponsiveContainer';
import { UnitCard } from '@/components/user/Units';
import { useBookingSheet, useUnit, useUnitFiles } from '@/lib/apiEndpoints';
import { useDownloadSheet } from '@/lib/useDownloadBookingSheet';
import { joinClasses } from '@/lib/utils';
import { UnitData } from '@/shared/types';

const BookingsChart = React.lazy(() => import('@/components/Bookings/BookingsChart'));

function UnitDocs({ unit }: { unit: UnitData }) {
  const { data: allUnitsDocs, error } = useUnitFiles();
  const unitDocs = allUnitsDocs?.filter(doc => doc.unit?.id === unit.id);

  return (
    <QueryWrapper data={unitDocs} error={error}>
      {data => <DocumentsList items={data} />}
    </QueryWrapper>
  );
}

function YearSelector({ year, setYear }: { year: number; setYear: (year: number) => void }) {
  const shownYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return [currentYear - 2, currentYear - 1, currentYear];
  }, []);

  return (
    <div className="flex gap-1">
      {shownYears.map(_year => (
        <Button
          key={_year}
          className={joinClasses('!p-1', year === _year && 'bg-yellow-300')}
          onClick={() => setYear(_year)}
        >
          {_year}
        </Button>
      ))}
    </div>
  );
}

const initialYear = new Date().getFullYear();

function BookingSheetDownload({ unit, year }: { unit: UnitData; year: number }) {
  const downloadXlsxMutation = useDownloadSheet({ year, unit, type: 'xlsx' });
  const downloadPdfMutation = useDownloadSheet({ year, unit, type: 'pdf' });

  const { data: bookingSheet } = useBookingSheet(unit.objectNumber, unit.unitNumber, year);

  if (!bookingSheet) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <button className="text-primary-900" onClick={() => downloadXlsxMutation.mutate()}>
        Buchungsblatt herunterladen (Excel)
        <DocumentArrowDownIcon className="h-5 w-5 inline text-primary-900 -translate-y-0.5" />
      </button>
      <button className="text-primary-900" onClick={() => downloadPdfMutation.mutate()}>
        (PDF)
        <DocumentArrowDownIcon className="h-5 w-5 inline text-primary-900 -translate-y-0.5" />
      </button>
    </div>
  );
}

function UnitDisplay({ unit }: { unit: UnitData }) {
  const [year, setYear] = useState(initialYear);

  return (
    <div className="flex flex-col gap-8">
      <UnitCard unit={unit} />
      {unit.isSevCsi && (
        <div className="self-end flex flex-col gap-2 items-end">
          <YearSelector year={year} setYear={setYear} />
          <BookingSheetDownload unit={unit} year={year} />
        </div>
      )}

      {unit.isSevCsi && (
        <ResponsiveContainer ratio={2.5} className="mt-12 -mx-2 md:mx-0">
          {({ width, height }) => (
            <BookingsChart unit={unit} width={width} height={height} year={year} />
          )}
        </ResponsiveContainer>
      )}

      {unit.isSevCsi && <BookingSheet unit={unit} year={year} />}

      <div>
        <h3 className="mt-4 mb-2 text-lg text-primary-900">Dokumente</h3>
        <UnitDocs unit={unit} />
      </div>
    </div>
  );
}

export default function UnitDetailPage() {
  const unitKey = useParams().unitKey as string;
  const { data: unit, isLoading } = useUnit(unitKey);

  if (!unit) {
    return (
      <PageContainer>
        <PageHeader>Einheit</PageHeader>
        {isLoading ? <LoadingPlaceholder /> : <div>Einheit nicht gefunden</div>}
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader>
        Einheit {unit.unitLabel} - {unit.objectLabel} {unit.objectNumber}
      </PageHeader>
      {unit && <UnitDisplay unit={unit} />}
    </PageContainer>
  );
}
