import { ErrorMessage } from './ErrorMessage';
import LoadingPlaceholder from './LoadingPlaceholder';

export default function QueryWrapper<T>({
  data,
  error,
  loadingText,
  children,
}: {
  data: T | undefined;
  error: unknown;
  loadingText?: React.ReactNode;
  children: (data: T) => React.ReactElement;
}) {
  if (error) {
    return (
      <div className="p-4">
        <ErrorMessage error={error as Error} />
      </div>
    );
  }

  if (data) {
    return children(data);
  } else {
    return <LoadingPlaceholder loadingText={loadingText} />;
  }
}
