import { useQuery } from '@tanstack/react-query';
import {
  BBResult,
  ContactData,
  ContactFormData,
  ContentSection,
  FileI,
  GlobalDoc,
  UnitData,
  UpdateableContactData,
  User
} from '@/shared/types';
import { apiUrl, getApi, postApi } from './api';

const MINUTES_5 = 1000 * 60 * 5;
const MINUTES_5_CACHE = {
  staleTime: MINUTES_5,
  cacheTime: MINUTES_5
};

async function getLoggedInUser() {
  return getApi('/api/user') as Promise<User | null>;
}

// used to check if logged in
export function useLoggedInUser() {
  return useQuery({
    queryKey: ['user'],
    queryFn: getLoggedInUser,
    staleTime: Infinity,
    cacheTime: Infinity
  }); // invalidate queries manually
}

function getUnits() {
  return getApi('/api/units') as Promise<UnitData[]>;
}

export function useUnits() {
  return useQuery({ queryKey: ['units'], queryFn: getUnits });
}

function getUnit(unitKey: string) {
  return getApi(`/api/unit/${unitKey}`) as Promise<UnitData>;
}

export function useUnit(unitKey: string) {
  return useQuery({ queryKey: ['unit', unitKey], queryFn: () => getUnit(unitKey) });
}

async function getContactData() {
  return getApi('/api/contact') as Promise<ContactData>;
}

export function useContactData() {
  return useQuery({
    queryKey: ['contact'],
    queryFn: getContactData
  });
}

export function usePageSection(page: string) {
  return useQuery({
    queryKey: ['page_section', page],
    queryFn: () => getApi(`/api/page_section/${page}`) as Promise<ContentSection[]>
  });
}

export function usePage(page: string) {
  return useQuery({
    queryKey: ['page', page],
    queryFn: () => getApi(`/api/page/${page}`) as Promise<{ title?: string; content: string }>
  });
}

export function globalFileDownloadLink(name: string) {
  return `${apiUrl}/api/storage/global/${encodeURIComponent(name)}`;
}

export function buildDownloadLink(name: string, directory: string) {
  const path = directory ? `${directory}/${name}` : name;
  return `${apiUrl}/api/storage/download/${path}`;
}

export function useGlobalFiles() {
  return useQuery({
    queryKey: ['globalFiles'],
    queryFn: () => getApi('/api/storage/global') as Promise<GlobalDoc[]>,
    ...MINUTES_5_CACHE
  });
}

// deprecated
export function usePersonalFiles() {
  return useQuery({
    queryKey: ['storage/personal'],
    queryFn: () => getApi('/api/storage/personal_files') as Promise<FileI[]>
  });
}

export function useObjectFiles() {
  return useQuery({
    queryKey: ['storage/object'],
    queryFn: () => getApi('/api/storage/object_files') as Promise<FileI[]>
  });
}

export function useEtKorrespFiles() {
  return useQuery({
    queryKey: ['storage/et_korresp'],
    queryFn: () => getApi('/api/storage/et_korresp_files') as Promise<FileI[]>
  });
}

export function useUnitFiles() {
  return useQuery({
    queryKey: ['storage/unit_files'],
    queryFn: () => getApi('/api/storage/unit_files') as Promise<FileI[]>
  });
}

// et_korresp and unit_files
export function useMyFiles() {
  return useQuery({
    queryKey: ['storage/my_files'],
    queryFn: () => getApi('/api/storage/my_files') as Promise<FileI[]>
  });
}

export function useObjectsAndMyFiles() {
  return useQuery({
    queryKey: ['storage/objects_my_files'],
    queryFn: () => getApi('/api/storage/objects_my_files') as Promise<FileI[]>
  });
}

export function useAllFiles(limit?: number) {
  return useQuery({
    queryKey: ['storage/all_files', { limit }],
    queryFn: () => getApi('/api/storage/all_files', { limit: String(limit) }) as Promise<FileI[]>
  });
}

export function postContactUpdate(data: Partial<UpdateableContactData>): Promise<ContactData> {
  return postApi('/api/contact', data);
}

export function postCommunicationPreference(data: {
  email: string;
  signature: string;
  signatureWidth: number;
  signatureHeight: number;
  location: string;
}): Promise<{ status: 'OK' }> {
  return postApi('/api/communication_preference', data);
}

export function useConfirmCommunicationPreference(validationId: string) {
  return useQuery({
    queryKey: ['confirm_communication_preference', validationId],
    queryFn: () => postApi('/api/confirm_communication_preference', { validationId })
  });
}

export function useBookingsGraph(unitId: number) {
  return useQuery({
    queryKey: ['bookings/bookings_graph', unitId],
    queryFn: () => postApi(`/api/bookings/bookings_graph`, { unitId, maxDepth: 1 })
  });
}

export function postBookingSheet(data: { unitKey: string; year: number }): Promise<BBResult> {
  return postApi('/api/bookings/booking_sheet', data);
}

export function useBookingSheet(objectNo: number, unitNo: number, year: number, enabled = true) {
  const unitKey = `${objectNo}-${unitNo}`;
  return useQuery({
    queryKey: ['bookings/booking_sheet', { unitKey, year }],
    queryFn: () => postBookingSheet({ unitKey, year })
  });
}

export function postContactForm(data: ContactFormData) {
  return postApi('/api/contact_form', data);
}

export function postNavigate(data: { location: string }) {
  return postApi('/api/navigation', data);
}
