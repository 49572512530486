import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isStatusErrorWithStatus, StatusError } from '@/lib/api';
import { useBookingSheet } from '@/lib/apiEndpoints';
import { formatDate } from '@/lib/dateUtils';
import { createRange, joinClasses } from '@/lib/utils';
import {
  EPS,
  MonthRange,
  TableRowData,
  createBookingRows,
  formatCurrency,
  sum
} from '@/shared/booking';
import { BBResult, UnitData } from '@/shared/types';
import { Button } from '../Layout';
import QueryWrapper from '../QueryWrapper';
import BookingsRaw from './BookingsRaw';

const isProduction = process.env.NODE_ENV === 'production';

function TH({ children, className, ...rest }: React.ThHTMLAttributes<HTMLHeadElement>) {
  return (
    <th className={joinClasses('px-2 py-1 first:pl-0', className)} {...rest}>
      {children}
    </th>
  );
}

function TD({ children, className, ...rest }: React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td className={joinClasses('p-2 first:pl-0', className)} {...rest}>
      {children}
    </td>
  );
}

function TDR({ children, className, ...rest }: React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <TD align="right" className={className} {...rest}>
      {children}
    </TD>
  );
}

function TDC({
  className,
  value,
  showZero = false,
  ...rest
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  value: number | undefined;
  showZero?: boolean;
}) {
  const valueN = value || 0;
  const isNeg = valueN <= -EPS;
  const showValue = showZero ? true : Math.abs(valueN) >= EPS;

  return (
    <TD align="right" className={joinClasses(isNeg && 'text-red-800', className)} {...rest}>
      {showValue ? formatCurrency(valueN) : undefined}
    </TD>
  );
}

function TR({ children, className }: React.HTMLAttributes<HTMLTableRowElement>) {
  return <tr className={className}>{children}</tr>;
}

function formatTennantName(name: string) {
  if (name === '[Leerstandsadresse]') {
    return 'Leerstand';
  }
  return name;
}

function AutoTable({ monthRange, rows }: { monthRange: MonthRange; rows: TableRowData[] }) {
  const hasSomeSumField = rows.some(x => x.hasSum);
  const labelWidth = Math.max(...rows.map(x => x.labelPadding || 0)) + 1;

  return (
    <div className="overflow-x-auto">
      <table className="text-xs table-auto w-full">
        <thead>
          <TR>
            <TH colSpan={labelWidth} align="left"></TH>
            {monthRange.map(x => (
              <TH key={x.dateString} className="border-b">
                {x.label}
              </TH>
            ))}
            {hasSomeSumField && <TH align="right">Summe</TH>}
          </TR>
        </thead>
        <tbody>
          {rows.map((row, idx) => {
            const labelPadding = row.labelPadding || 0;
            const hasSumField = row.hasSum;
            const labelSpan = labelWidth - labelPadding;

            return (
              <React.Fragment key={idx}>
                <TR key={idx} className={labelPadding === 0 ? 'border-t' : undefined}>
                  {labelPadding > 0 &&
                    createRange(0, labelPadding).map(i => <TD key={i} className="pl-0" />)}
                  <TD
                    colSpan={labelSpan}
                    className={joinClasses(
                      labelPadding === 0
                        ? 'font-bold'
                        : labelPadding === 2
                          ? 'text-gray-400'
                          : undefined
                    )}
                    title={row.title}
                  >
                    {row.label}
                  </TD>

                  {row.values.map((val, idx) => (
                    <TDC
                      key={idx}
                      value={val}
                      title={row.valueLabels?.[idx]}
                      showZero={row.showZeros}
                      className={row.valueClassName}
                    />
                  ))}
                  {hasSumField ? (
                    <TDC value={sum(row.values)} showZero={row.showZeros} className="font-bold" />
                  ) : (
                    <TD />
                  )}
                </TR>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function MieterTable({ data, year }: { data: BBResult; year: number }) {
  return (
    <table className="text-xs table-auto w-full">
      <thead>
        <TR>
          <TH colSpan={2} />
          {data.mieterData.map(m => (
            <TH key={m.mietContract.VE_KEY} className="text-right">
              {formatTennantName(m.mieter.NAME)}
            </TH>
          ))}
        </TR>
      </thead>
      <tbody>
        <TR>
          <TD colSpan={2} className="font-bold">
            Mietbegin
          </TD>
          {data.mieterData.map(m => (
            <TDR key={m.mietContract.VE_KEY}>{formatDate(m.mietContract.VEBEGIN)}</TDR>
          ))}
        </TR>
        <TR>
          <TD colSpan={2} className="font-bold">
            Mietende
          </TD>
          {data.mieterData.map(m => (
            <TDR key={m.mietContract.VE_KEY}>{formatDate(m.mietContract.VEENDE)}</TDR>
          ))}
        </TR>
        <TR>
          <TD colSpan={2} className="font-bold">
            Monatliche Sollstellung
          </TD>
          {data.mieterData.map(m => (
            <TDC
              key={m.mietContract.VE_KEY}
              value={sum([m.dauerb.miete, m.dauerb.nk, m.dauerb.tg])}
            />
          ))}
        </TR>
        <TR>
          <TD />
          <TD>Kaltmiete lt. Mietvertrag</TD>
          {data.mieterData.map(m => (
            <TDC key={m.mietContract.VE_KEY} value={m.dauerb.miete} />
          ))}
        </TR>
        <TR>
          <TD />
          <TD>Nebenkostenvorauszahlung</TD>
          {data.mieterData.map(m => (
            <TDC key={m.mietContract.VE_KEY} value={m.dauerb.nk} />
          ))}
        </TR>
        <TR>
          <TD />
          <TD>TG-Miete</TD>
          {data.mieterData.map(m => (
            <TDC key={m.mietContract.VE_KEY} value={m.dauerb.tg} />
          ))}
        </TR>
        <TR>
          <TD colSpan={2} className="font-bold">
            Ergebnis Abrechnung Vorjahr
          </TD>
          {data.mieterData.map(m => (
            <TDC key={m.mietContract.VE_KEY} value={m.totalNk > 0 ? m.totalNk : undefined} />
          ))}
        </TR>
        <TR>
          <TD colSpan={2} className="font-bold">
            Anfangsbestand Mietersaldo zum 1.1.
          </TD>
          {data.mieterData.map(m => (
            <TDC key={m.mietContract.VE_KEY} value={m.mieterSaldoYear[year - 1]} showZero />
          ))}
        </TR>
      </tbody>
    </table>
  );
}

function Sonderkosten({ sonderKostenFlat }: { sonderKostenFlat: { text: string; date: Date }[] }) {
  if (sonderKostenFlat.length === 0) {
    return null;
  }

  return (
    <>
      <h3 className="mt-8 mb-2 text-lg text-primary-900">Sonderkosten</h3>
      <ul className="text-xs list-disc list-inside">
        {sonderKostenFlat.map((x, idx) => (
          <li key={idx}>
            {formatDate(x.date)}: {x.text}
          </li>
        ))}
      </ul>
    </>
  );
}

function _BookingSheet({ data, year }: { data: BBResult; year: number }) {
  const searchParams = useSearchParams()[0];

  const [showRawBookings, setShowRawBookings] = useState(searchParams.get('bookings') === 'true');
  const dataMapped = useMemo(() => {
    return createBookingRows(data, year);
  }, [data, year]);

  const monthRange = dataMapped.monthRange;

  return (
    <div>
      {/* <h3 className="mb-2 text-lg text-primary-900">Übersicht</h3>
      <AutoTable monthRange={monthRange} rows={dataMapped.ubersichtRows} /> */}

      <h3 className="mt-4 mb-2 text-lg text-primary-900">Eigentümer</h3>
      <AutoTable monthRange={monthRange} rows={dataMapped.ownerRows} />

      <Sonderkosten sonderKostenFlat={dataMapped.sonderKostenFlat} />

      <h3 className="mt-8 mb-2 text-lg text-primary-900">Mieter</h3>
      <AutoTable monthRange={monthRange} rows={dataMapped.mieterRows} />

      {/* <h3 className="mt-8 text-lg text-primary-900">Mieterdaten</h3>
      <MieterTable data={data} year={year} /> */}

      <div className="mt-4">
        {showRawBookings && <BookingsRaw data={data} />}
        {!isProduction && (
          <Button onClick={() => setShowRawBookings(!showRawBookings)}>
            Detailierte Buchungen {showRawBookings ? 'ausblenden' : 'anzeigen'}
          </Button>
        )}
      </div>
    </div>
  );
}

export default function BookingsSheet({ unit, year }: { unit: UnitData; year: number }) {
  const { data: bookingSheet, error: bookingSheetError } = useBookingSheet(
    unit.objectNumber,
    unit.unitNumber,
    year
  );

  if (bookingSheetError && isStatusErrorWithStatus(bookingSheetError, 404)) {
    return null;
  }

  return (
    <QueryWrapper data={bookingSheet} error={bookingSheetError}>
      {data => <_BookingSheet data={data} year={year} />}
    </QueryWrapper>
  );
}
